<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        :label="$t('SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :items-per-page="itemsPerPage"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPage: itemsPerPage,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right'
      }"
    >
      <template v-slot:item.name="{ item }">
        <strong>{{ item.warehouseName }}</strong> | {{ item.name }}
        <small>({{ item.unitShortName }})</small>
      </template>
      <template v-slot:item.unitValue="{ item }">
        ({{
          (parseFloat(item.unitValue) / parseFloat(item.packageValue)).toFixed(
            2
          )
        }}
        {{ item.packageName }}) <strong>{{ item.unitValue }}</strong>
        <small> {{ item.unitShortName }}</small>
      </template>
      <template v-slot:item.poItems="{ item }">
        <template v-for="poItem in item.poItems">
          <v-row v-bind:key="'poItem' + poItem.id">
            <template>
              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("LOT") }}:</strong><br />
                  {{ poItem.lot }}
                </div>
              </v-col>
              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("LOT_EXP_DATE") }}:</strong><br />
                  {{ poItem.lotExpDate }}
                </div>
              </v-col>
              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("SENT") }}:</strong><br />
                  ({{
                    (
                      parseFloat(poItem.unitValue) /
                      parseFloat(item.packageValue)
                    ).toFixed(2)
                  }}
                  {{ item.packageName }}) {{ poItem.unitValue }}
                  <small> {{ poItem.unitShortName }}</small>
                </div>
              </v-col>

              <v-col cols="12" sm="2">
                <div v-if="action != 'receiving'">
                  <strong>{{ $t("RECEIVED") }}:</strong><br />
                  {{ poItem.receivedUnitValue }} {{ poItem.unitShortName }}
                </div>
                <v-text-field
                  v-else
                  hide-details
                  type="number"
                  :label="$t('RECEIVED')"
                  v-model="poItem.receivedUnitValue"
                  :suffix="poItem.unitShortName"
                  :step="poItem.unitNumberType == 2 ? 1 : 0.001"
                  min="0"
                  @blur="filterReceivedUnitValue(poItem)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <div v-if="action != 'receiving'">
                  <strong>{{ $t("RETURNED") }}:</strong><br />
                  {{ poItem.returnedUnitValue }} {{ poItem.unitShortName }}
                </div>
                <v-text-field
                  v-else
                  hide-details
                  type="number"
                  :label="$t('RETURNED')"
                  v-model="poItem.returnedUnitValue"
                  :suffix="poItem.unitShortName"
                  :step="poItem.unitNumberType == 2 ? 1 : 0.001"
                  min="0"
                  @blur="filterReturnedUnitValue(poItem)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <div>
                  <strong>{{ $t("MISSED") }}:</strong><br />
                  {{ poItem.missedUnitValue }} {{ poItem.unitShortName }}
                </div>
              </v-col>

              <v-col cols="12" sm="2" v-if="poItem.inSenderUnitValue > 0">
                <div>
                  <strong>{{ $t("IN_SENDER") }}:</strong><br />
                  {{ poItem.inSenderUnitValue }} {{ poItem.unitShortName }}
                </div>
              </v-col>

              <v-col cols="12" sm="2" v-if="poItem.inReceiverUnitValue > 0">
                <div>
                  <strong>{{ $t("IN_RECEIVER") }}:</strong><br />
                  {{ poItem.inReceiverUnitValue }} {{ poItem.unitShortName }}
                </div>
              </v-col>
            </template>
          </v-row>
        </template>
      </template>
      <template v-if="showTotalPrice" slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            {{ $t("TOTAL_PRICE") }}:
            {{ itemsTotal }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
.v-data-table >>> td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
<script>
import _ from "lodash";
export default {
  name: "StockPurchaseOrderRequestedItemsTable",
  inheritAttrs: false,
  props: {
    action: {
      type: String,
      default: "review"
    },
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    itemsTotal: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: "",
      itemsPerPage: -1,
      rowsPerPageItems: [-1, 100, 10]
    };
  },
  components: {},
  beforeMount() {},
  mounted() {
    this.setMaxReceivedValue();
  },
  computed: {},
  watch: {},
  methods: {
    setMaxReceivedValue() {
      let vm = this;
      //IF status is Shipped -> Receiving, правим инпут за получаване да има дефаулт макса за получаване
      if (_.size(vm.items) > 0 && vm.action == "receiving") {
        vm.items.forEach(requestedItem => {
          requestedItem.poItems.forEach(poItem => {
            poItem.receivedUnitValue = poItem.unitValue;
          });
        });
      }
    },
    findInSelect(id, options) {
      console.log(options);
      console.log(id);
      let item = _.find(options, { id: id });
      if (item) {
        return item.name;
      } else {
        return "-";
      }
    },
    filterReceivedUnitValue(poItem) {
      if (
        parseFloat(poItem.unitValue) - parseFloat(poItem.returnedUnitValue) <
        parseFloat(poItem.receivedUnitValue)
      ) {
        //
        poItem.receivedUnitValue =
          parseFloat(poItem.unitValue) - parseFloat(poItem.returnedUnitValue);
      }
      poItem.receivedUnitValue = this.formatItemField(
        poItem,
        Math.abs(poItem.receivedUnitValue)
      );
      poItem.missedUnitValue = this.formatItemField(
        poItem,
        Math.abs(
          parseFloat(poItem.unitValue) -
            (parseFloat(poItem.returnedUnitValue) +
              parseFloat(poItem.receivedUnitValue))
        )
      );
    },
    filterReturnedUnitValue(poItem) {
      if (
        parseFloat(poItem.unitValue) - parseFloat(poItem.receivedUnitValue) <
        parseFloat(poItem.returnedUnitValue)
      ) {
        //
        poItem.returnedUnitValue =
          parseFloat(poItem.unitValue) - parseFloat(poItem.receivedUnitValue);
      }
      poItem.returnedUnitValue = this.formatItemField(
        poItem,
        Math.abs(poItem.returnedUnitValue)
      );
      poItem.missedUnitValue = this.formatItemField(
        poItem,
        Math.abs(
          parseFloat(poItem.unitValue) -
            (parseFloat(poItem.returnedUnitValue) +
              parseFloat(poItem.receivedUnitValue))
        )
      );
    },
    formatItemField(poItem, value) {
      if (poItem.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    }
  }
};
</script>
