<template>
  <div>
    <v-simple-table v-if="_.size(stockPurchaseOrder) > 0">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>{{ $t("STATUS") }}:</th>
            <th>
              {{ $t("FROM") }}
            </th>
            <th>
              {{ $t("TO") }}
            </th>
            <th>{{ $t("USER") }}:</th>
          </tr>

          <tr
            v-for="(status, oschIndex) in stockPurchaseOrder.trackStatusChanges"
            v-bind:key="'stockPurchaseOrderStatusChange-' + oschIndex"
          >
            <td class="text-strong">
              {{ status.stockPurchaseOrderStatusName }}
              <div v-if="status.stockPurchaseOrderStatusId == 4">
                <router-link
                  target="_blank"
                  :to="{
                    name: 'stock-action-review',
                    params: { id: stockPurchaseOrder.stockActionSent.id }
                  }"
                >
                  {{ stockPurchaseOrder.stockActionSent.id }}
                </router-link>
              </div>
              <div v-if="status.stockPurchaseOrderStatusId == 5">
                <router-link
                  target="_blank"
                  :to="{
                    name: 'stock-action-review',
                    params: { id: stockPurchaseOrder.stockActionReceived.id }
                  }"
                >
                  {{ stockPurchaseOrder.stockActionReceived.id }}
                </router-link>
              </div>
            </td>
            <td class="text-strong">
              {{ status.fromDate | formatDateTime }}
            </td>
            <td class="text-strong">
              {{ status.toDate | formatDateTime }}
            </td>
            <td class="text-strong">
              <div
                v-if="status.userName != null"
                class="d-flex align-items-center pt-2 pb-2"
              >
                <div class="symbol symbol-50 symbol-light mr-4">
                  <span class="symbol-label"
                    ><img
                      src="/media/svg/avatars/001-boy.svg"
                      alt=""
                      class="h-75 align-self-end"
                  /></span>
                </div>
                <div>
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ status.userName }}</a
                  ><span class="text-muted font-weight-bold d-block">{{
                    status.userRoleName
                  }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<style lang="scss" scoped>
td.text-strong {
  font-weight: bold;
}
</style>

<script>
export default {
  name: "ChangeStatusTable",
  mixins: [],
  props: {
    stockPurchaseOrder: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>
