var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm._.size(_vm.stockPurchaseOrder) > 0)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("STATUS"))+":")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("FROM"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("TO"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t("USER"))+":")])]),_vm._l((_vm.stockPurchaseOrder.trackStatusChanges),function(status,oschIndex){return _c('tr',{key:'stockPurchaseOrderStatusChange-' + oschIndex},[_c('td',{staticClass:"text-strong"},[_vm._v(" "+_vm._s(status.stockPurchaseOrderStatusName)+" "),(status.stockPurchaseOrderStatusId == 4)?_c('div',[_c('router-link',{attrs:{"target":"_blank","to":{
                  name: 'stock-action-review',
                  params: { id: _vm.stockPurchaseOrder.stockActionSent.id }
                }}},[_vm._v(" "+_vm._s(_vm.stockPurchaseOrder.stockActionSent.id)+" ")])],1):_vm._e(),(status.stockPurchaseOrderStatusId == 5)?_c('div',[_c('router-link',{attrs:{"target":"_blank","to":{
                  name: 'stock-action-review',
                  params: { id: _vm.stockPurchaseOrder.stockActionReceived.id }
                }}},[_vm._v(" "+_vm._s(_vm.stockPurchaseOrder.stockActionReceived.id)+" ")])],1):_vm._e()]),_c('td',{staticClass:"text-strong"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(status.fromDate))+" ")]),_c('td',{staticClass:"text-strong"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(status.toDate))+" ")]),_c('td',{staticClass:"text-strong"},[(status.userName != null)?_c('div',{staticClass:"d-flex align-items-center pt-2 pb-2"},[_c('div',{staticClass:"symbol symbol-50 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label"},[_c('img',{staticClass:"h-75 align-self-end",attrs:{"src":"/media/svg/avatars/001-boy.svg","alt":""}})])]),_c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(status.userName))]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(status.userRoleName))])])]):_vm._e()])])})],2)]},proxy:true}],null,false,1837500797)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }