<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          {{ $t("STOCK_PURCHASE_ORDER_REQUEST_REVIEW") }}
        </h3>
      </template>
      <template v-slot:body>
        <b-row>
          <StockInfoPanels
            v-if="!firstLoader"
            :number="stockPurchaseOrder.id"
            :fromStoreName="stockPurchaseOrder.fromStoreName"
            :toStoreName="stockPurchaseOrder.toStoreName"
            :userName="stockPurchaseOrder.userName"
          >
          </StockInfoPanels>
          <b-col :sm="12">
            <ChangeStatusTable
              :stockPurchaseOrder="stockPurchaseOrder"
            ></ChangeStatusTable>
          </b-col>
          <b-col :sm="12">
            <StockPurchaseOrderRequestedItemsTable
              v-if="
                !firstLoader && _.size(stockPurchaseOrder.requestedItems) > 0
              "
              :headers="dynamicHeaders"
              :items="stockPurchaseOrder.requestedItems"
              :showTotalPrice="showTotalPrice"
              :action="action"
            >
            </StockPurchaseOrderRequestedItemsTable>
          </b-col>
          <b-col
            :sm="12"
            v-if="!firstLoader && stockPurchaseOrder.actionNotes != ''"
          >
            <v-alert border="top" colored-border type="info" elevation="2">
              <div class="text-h6">{{ $t("NOTES") }}:</div>
              {{ stockPurchaseOrder.actionNotes }}
            </v-alert>
          </b-col>
        </b-row>
        <b-form>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock == true">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <BackButton></BackButton>
        <CustomButton
          v-if="stockPurchaseOrder.statusId == 4"
          :label="$t('REJECT')"
          :isLoading="isLoadingStock"
          :iconClass="'fa fa-times-circle'"
          :colorClass="'btn btn-danger'"
          v-on:save="rejectShipped($event)"
        ></CustomButton>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_STOCK_PURCHASE_ORDER_REQUESTED } from "@/modules/stock/store/stock.module";
import { REJECT_SHIPPED_STOCK_PURCHASE_ORDER } from "@/modules/stock/store/stock.module";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import CustomButton from "@/view/content/forms/components/CustomButton.vue";

import StockPurchaseOrderRequestedItemsTable from "@/modules/stock/components/purchase-orders/StockPurchaseOrderRequestedItemsTable.vue";
import ChangeStatusTable from "@/modules/stock/components/purchase-orders/ChangeStatusTable.vue";

import AuthService from "@/core/services/auth.service";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StockPurchaseOrderRequestReview",
  mixins: [],
  data() {
    return {
      errors: {},
      action: "review",
      firstLoader: true,
      stockPurchaseOrder: {},
      showTotalPrice: false,
      toggleEchoApiLog: false,
      startHeaders: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        }
      ],
      endHeaders: []
    };
  },
  components: {
    KTCard,
    StockInfoPanels,
    StockPurchaseOrderRequestedItemsTable,
    ChangeStatusTable,
    CustomButton
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Stock purchase order list",
        route: { name: "stock-purchase-orders-list" }
      }
    ]);

    vm.getStockPurchaseOrderRequested();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    dynamicHeaders() {
      let vm = this;
      let dynamicHeaders = vm.startHeaders;

      let addHeaders = [
        {
          text: this.$t("STOCK_PURCHASE_ORDER_REQUESTED_UNIT_VALUE"),
          value: "unitValue",
          sortable: true
        },
        {
          text: this.$t("LOTS"),
          value: "poItems",
          sortable: true
        }
      ];
      //Add headers if needed between start and endheaders as it is in StockActionReview.vue

      dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      return dynamicHeaders;
    }
  },
  methods: {
    getStockPurchaseOrderRequested() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_STOCK_PURCHASE_ORDER_REQUESTED, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.stockPurchaseOrder = data.data;
          });
        })
        .catch(response => {
          // if (response.status === 404) {
          vm.$notify({
            group: "notify",
            type: "error",
            text: response.data.message
          });
          this.$router.push({ name: "stock-purchase-orders-list" });
          // }
        });
    },
    rejectShipped() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = {
            stockPurchaseOrderId: vm.stockPurchaseOrder.id
          };
          vm.$store
            .dispatch(REJECT_SHIPPED_STOCK_PURCHASE_ORDER, payload)
            .then(data => {
              vm.errors = data.data.errors;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              AuthService.refreshUserData();
              vm.getStockPurchaseOrderRequested();
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    showEchoApiLog() {
      this.toggleEchoApiLog = !this.toggleEchoApiLog;
    }
  }
};
</script>
